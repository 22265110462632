/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Manjari:wght@100;400;700&display=swap');

$PRIMARY: rgb(120,90,255);

body {
  font-family: "Inter";
  background: #f7fafc;
}
main {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  background: #fff;
}
nav.logo {

}
a {
  cursor: pointer;
}

.text-color {
  color: $PRIMARY;
}
.color {
  background: $PRIMARY;
  background: linear-gradient(180deg, rgba(120,90,255,1) 0%, rgba(77,50,197,1) 100%);
}
// .container {
//   height: 100%;
//   max-width: 480px;
// }
.msg-container {
  height: 100%;
  max-width: 640px;
  overflow: hidden;
  overflow-y: scroll;
}
.msg-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.input-container {
  max-width: 640px;
  padding: 24px 12px;
  width: 100%;
  margin: 0px auto;
  input {
    border: 0px;
    width: 100%;
    box-shadow: 0px 3px 4px rgba(0,0,0,.1);
    &:focus, &:active {
      border: 0px;
      outline: 0px;
    }
  }
  .suggestion-chips {
    margin-bottom: 16px;
    .suggestion-chip {
      display: inline-block;
      background-color: transparent;
      border: 3px solid $PRIMARY;
      color: $PRIMARY;
      padding: 8px 12px;
      border-radius: 100px;
      margin-right: 16px;
      cursor: pointer;
      &:hover {
        color: white;
        background: $PRIMARY;
      }
    }
  }
}
.btn-icon {
  display: inline-block;
  padding: 18px;
  font-size: 24px !important;
  color: $PRIMARY;
}
.btn-icon:hover {
  color: $PRIMARY !important;
}
input, select {
  height: auto !important;
  border-radius: 100px !important;
  padding: 12px 24px !important;
}
label {
  display: block;
  text-align: left;
}
// .logo {
//   background: #efefef;
//   width: 100px;
//   height: 100px;
//   border-radius: 100%;
//   margin: 0px auto;
// }
nav {
  .logo {
    background: $PRIMARY;
    width: 36px;
    height: 36px;
  }
}
.btn {
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 13px;
  color: white;
  font-weight: bold;
  padding: 12px 24px;
  min-width: 150px;
  border-radius: 100px;
  white-space: nowrap;
  i.mdi {
    margin-right: 12px;
  }
}
.btn-default {
  color: black;
}
.btn-primary {
  color: white !important;
}
.bubble {
  display: flex;
  margin-bottom: 16px;
}
.bubble-left {
  justify-content: start;
}
.bubble-right {
  justify-content: end;
}
.bubble .msg {
  background: white;
  padding: 16px 24px;
  border-radius: 25px;
  box-shadow: 0px 3px 4px rgba(0,0,0,.1);
  // &.card {
  //   overflow: hidden;
  //   padding: 0px;
  //   max-width: 300px;
  //   width: 100%;
  //   .card-image {
  //     width: 100%;
  //     min-height: 150px;
  //     background-color: #efefef;
  //   }
  //   .card-body {
  //     .title {
  //       font-weight: bold;
  //     }
  //     p {
  //       margin-bottom: 0px;
  //       font-size: 14px;
  //     }
  //   }
  // }

}
.bubble.bubble-right .msg {
  background: $PRIMARY;
  background: linear-gradient(180deg, rgba(120,90,255,1) 0%, rgba(77,50,197,1) 100%);
  color: white;
}
.loading {
  display: flex;
  flex-direction: row;
  align-items: cen  ter;
  justify-content: center;
}

.dot1, .dot2, .dot3 {
  width: 5px;
  height: 5px;
  border:double;
  border-color:$PRIMARY;
  border-radius: 50%;
  margin: 6px;
  color: $PRIMARY;
}

.dot1 {
  animation: jump 1s -0.32s linear infinite;
  background: $PRIMARY;
}
.dot2 {
  animation: jump 1s -0.16s linear infinite;
  background: $PRIMARY;
}
.dot3 {
  animation: jump 1s linear infinite;
  background: $PRIMARY;
}

@keyframes jump {
  0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
  } 40% {
      -webkit-transform: scale(2.0);
      transform: scale(2.0);
  }
}

.body {
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
// .sidebar {
//   padding-top: 32px;
//   min-width: 230px;
//   max-width: 300px;
// }
// .sidebar p {
//   padding: 16px;
//   font-size: 13px;
// }
// .sidebar ul {
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
// }
// .sidebar ul li {
//   white-space: nowrap;
// }
// .sidebar ul li.divider {
//   border-bottom: 1px solid rgba(0,0,0,.1);
//   margin: 12px 0px;
// }
// .sidebar ul li a {
//   display: block;
//   padding: 8px 24px;
//   border-radius: 0px 100px 100px 0px;
//   color: #606060;
//   font-weight: bold;
//   text-transform: uppercase;
// }
// .sidebar ul li a:hover {
//   color: rgb(120,90,255);
//   text-decoration: none;
// }
// .sidebar ul li a.active {
//   background: #E8E8E8;
//   color: rgb(120,90,255);
// }
// .sidebar ul li a i {
//   font-size: 19px;
//   margin-right: 16px;
// }
.content {
  width: 100%;
  position: relative;
  overflow-y: scroll
}

.emoji-selector {

}
label.emoji {
    cursor: pointer;
    text-align: center;
}
label.emoji input {
    display: none;
}
label.emoji input + i {
    color: #666;
    font-size: 32px;
    display: block;
}
label.emoji input:checked + i, label.emoji input:checked + i + span {
    color: rgb(120,90,255);
}
label.emoji input + i + span {
    font-size: 13px;
    color: #666;
}
textarea.form-control {
    border-radius: 10px;
    min-height: 150px;
    padding: 12px 24px;
    resize: vertical;
    min-height: 200px;
    max-height: 400px;
}
.btn.btn-danger-text {
    color: #dc3545 !important;
}

a:hover {
  color: #ffffff;
}
.journal-item p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0px;
}
.journal-item b {
  margin-right: 8px;
}

.cursor-pointer{
  cursor: pointer;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.rating > input {
  display: none;
}
.rating > label i {
  position: relative;
  font-size: 36px;
  cursor: pointer;
}
.rating > label i:before {
  color: #BDBDBD;
}
.rating > input:checked ~ label i:before {
  color: #FDD835;
}




// css for ui revamp


main {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  background: #fff;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Manjari';
}


a:hover {
  color: #ffffff;
}
label {
  font-size: 13px;
  color: #333;
}
a,button {
  font-weight: bold;
}
a:hover {
  color: black;
}
button.btn {
  font-size: 13px;
}
.btn-secondary {
  color: black !important;
}
section {
  padding: 1.5em;
}




/* sidebar component */
.component-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  min-width: 300px;
  /* background-color: #f9f9f9; */
  border-right: 1px solid rgba(0,0,0,.05);
}
.component-sidebar .sidebar-menu {

}
.component-sidebar a.menu-item {
  color: #444;
  border-left: 4px solid transparent;
  display: block;
  padding: 0em 1.5em;
  margin: 2em 0em;
}
.component-sidebar a.menu-item:hover {
  text-decoration: none;
}
.component-sidebar a.menu-item.active {
  border-left: 4px solid #a6a5c9;
  color: #8885D2;
}
.component-sidebar .sidebar-menu .logo {
  margin: 24px 0px;
  width: 80%;
}
.component-sidebar .sidebar-footer {

}
.component-sidebar .sidebar-footer p {
  padding: 1em 1.5em;
}


/* body component */
.body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* top-bar component */

.component-top-bar {
  border-bottom: 1px solid rgba(0,0,0,.05);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  /* background: #8885D2; */
}
.component-top-bar #toggleSidebar {
  display: none;
  padding: 12px;
  margin-right: 12px;
  i.mdi {
    font-size: 24px;
  }
}
.component-top-bar h3 {
  font-weight: bold;
}

.component-top-bar a {

}

.component-top-bar select {
  padding: 12px !important;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,.1);
  margin-left: 8px;
  cursor: pointer;
}

.component-content {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.component-content::-webkit-scrollbar {
  width: 0px;
}

.component-journal {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  column-gap: 24px;
  padding: 24px;
}

.component-journal-calendar {
  width: 100%;
  border-right: 1px solid rgba(0,0,0,.05);
}
.component-journal-calendar .calendar-header {
  display: flex;
  justify-content: space-between;
  margin: 0px 12px 24px 12px;
}
.component-journal-calendar .calendar-header .calendar-move {
}
.component-journal-calendar .calendar-header .calendar-move i {
  font-size: 24px;
  margin: 12px;
}
.component-journal-calendar .calendar-days {
  display: flex;
  margin-bottom: 24px;
}
.component-journal-calendar .calendar-days span {
  color: #a9a9a9;
  width: 14.28%;
  text-align: center;
}
.component-journal-calendar .calendar-date-items {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.component-journal-calendar .calendar-date-items .calendar-date-item {
  width: 14.28%;
  height: 120px;
  box-sizing: border-box;
  padding: 8px;
  text-align: center;
}
.component-journal-calendar .calendar-date-items .calendar-date-item:hover {
  background-color: rgba(0,0,0,.03);
}
.component-journal-calendar .calendar-date-items .calendar-date-item span {
  color: #333;
  font-size: 13px;
  padding: 4px 6px;
}
.component-journal-calendar .calendar-date-items .calendar-date-item span.prev-next-month {
  color: #a9a9a9;
}
.component-journal-calendar .calendar-date-items .calendar-date-item span.selected {
  background-color: black;
  color: white;
  border-radius: 100px;
}
.component-journal-calendar .calendar-date-items .calendar-date-item .calendar-journal {
  background: #8885D2;
  border: 1px solid rgba(0,0,0,.03);
  border-radius: 5px;
  font-size: 13px;
  text-align: left;
  color: white;
  padding: 0px 4px;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 4px;
}


.component-journal-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.component-journal-list .journal-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
}
.component-journal-list .journal-list-header h5 {
  margin-bottom: 0px;
}
.component-journal-list .journal-summary-items {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.component-journal-list .journal-summary-items::-webkit-scrollbar {
  width: 0px;
}
.component-journal-list .journal-summary-items .journal-summary {
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 20px;
  padding: 18px;
  margin-bottom: 24px;
}
.component-journal-list .journal-summary-items .journal-summary p {
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0px;
  margin-bottom: 24px;
}

.mood {
  font-size: 13px;
  font-weight: bold;
  background-color: #8885D2;
  color: white;
  padding: 6px 12px;
  border-radius: 5px;
}

.component-emoji-selector {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.component-emoji-selector label {
  cursor: pointer;
  text-align: center;
}
.component-emoji-selector label input {
  display: none;
}
.component-emoji-selector label input + span {
  color: #777;
}
.component-emoji-selector label i {
  display: block;
  font-size: 36px;
  color: #777;
}
.component-emoji-selector label input:checked + i {
  color: #8885D2;
}

.component-emoji-selector label img {
  opacity: .4;
  height: 40px;
}
.component-emoji-selector label input:checked + img {
  opacity: 1;
  height: 60px;
}

@media screen and (max-width: 768px) {
  .body {
    width: 100% !important;
  }
  .component-top-bar #toggleSidebar {
    display: inline-block;
  }
}